var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-screen flex w-full bg-img" }, [
    _c(
      "div",
      {
        staticClass:
          "vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center",
      },
      [
        _c("vx-card", [
          _c(
            "div",
            {
              staticClass: "full-page-bg-color",
              attrs: { slot: "no-body" },
              slot: "no-body",
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center",
                  },
                  [
                    _c("img", {
                      staticClass: "mx-auto",
                      attrs: {
                        src: require("@/assets/images/pages/forgot-password.png"),
                        alt: "login",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg",
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "p-8" },
                      [
                        _c("div", { staticClass: "vx-card__title mb-8" }, [
                          _c("h4", { staticClass: "mb-4" }, [
                            _vm._v("Recover your password"),
                          ]),
                          _c("p", [
                            _vm._v(
                              "Please enter your email address and we'll send you instructions on how to reset your password."
                            ),
                          ]),
                        ]),
                        _c("vs-input", {
                          staticClass: "w-full mb-8",
                          attrs: {
                            type: "email",
                            "label-placeholder": "Email",
                          },
                          model: {
                            value: _vm.value1,
                            callback: function ($$v) {
                              _vm.value1 = $$v
                            },
                            expression: "value1",
                          },
                        }),
                        _c(
                          "vs-button",
                          {
                            staticClass: "px-4 w-full md:w-auto",
                            attrs: { type: "border", to: "/pages/login" },
                          },
                          [_vm._v("Back To Login")]
                        ),
                        _c(
                          "vs-button",
                          {
                            staticClass:
                              "float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0",
                          },
                          [_vm._v("Recover Password")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ]),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }